
// Services
import ApiService from './api'
import AuthService from './auth'

export default class UserService {
	async getUser(id = false) {
		const apiService = new ApiService()

		if(!id) id = localStorage.getItem('loggedIn')

		const response = await apiService.get(`users/${id}`)

		if(response.data) {
			return {
				success: true,
				data: response.data,
			}
		}
		else {
			return {
				success: false,
				message: 'Failed to get user',
			}
		}
	}

	async updateUser(formData, id = false) {
		const apiService = new ApiService()

		let body = JSON.stringify(formData),
		files = formData.hasOwnProperty('identification')

		if(!id) id = localStorage.getItem('loggedIn')

		if(files) {
			body = new FormData()

			body.append('identification', formData?.identification)
		}

		const response = await apiService.put(`users/${id}`, {
			body: body,
		}, files)

		if(response.data) {
			return {
				success: true,
				data: response.data,
			}
		}
		else {
			return {
				success: false,
				message: 'Failed to update user',
				...(response?.errors && { errors: response?.errors }),
			}
		}
	}

	async deleteUser(id = false) {
		const apiService = new ApiService()

		if(!id) {
			id = localStorage.getItem('loggedIn')
		}

		const response = await apiService.delete(`users/${id}`)

		if(response.success) {
			return {
				success: true,
				data: response.data,
			}
		}
		else {
			return {
				success: false,
				message: 'Failed to delete user',
			}
		}
	}

	async setBillingAddress(billingAddress, id = false) {
		const apiService = new ApiService()

		if(!id) id = localStorage.getItem('loggedIn')

		billingAddress.is_billing = 1;
		billingAddress.is_primary = 0;
		billingAddress.is_shipping = 0;
		billingAddress.country_code = 'GB';

		return await apiService.put(`users/${id}/address/billing`, {
			body: JSON.stringify(billingAddress),
		})
	}

	async setImportedUserPassword(formData) {
		const apiService = new ApiService(),
			authService = new AuthService()

		const response = await apiService.post(`registration/complete`, {
			body: JSON.stringify(formData),
		})

		if(response.data) {
			const user = response.data
			await authService.login(user['email'], formData['password'])
				.catch(err => console.error(err))
			return {
				success: true,
				data: user,
			}
		}
		else {
			if (response?.errors?.token) {
				response.errors.password = response.errors.token
			}
			return {
				success: false,
				message: 'Failed to set password',
				...(response?.errors && { errors: response?.errors }),
			}
		}
	}

	async resendVerificationLink(token) {
		const apiService = new ApiService()
		const response = await apiService.post(`email/verify/resend`, {
			body: JSON.stringify({registration_token: token}),
		})

		if(response.success) {
			return {
				success: true
			}
		}
		else {
			return {
				success: false,
				...(response?.errors && { errors: response?.errors }),
			}
		}
	}

	async resendResetPasswordLink(email) {
		const apiService = new ApiService()
		const response = await apiService.post(`password/request`, {
			body: JSON.stringify({email: email}),
		})

		if(response.success) {
			return {
				success: true
			}
		}
		else {
			return {
				success: false,
				...(response?.errors && { errors: response?.errors }),
			}
		}
	}

	async resetPassword(formData) {
		const apiService = new ApiService()
		const response = await apiService.post(`password/reset`, {
			body: JSON.stringify(formData),
		})

		if(response.success) {
			return {
				success: true
			}
		}
		else {
			return {
				success: false,
				...(response?.errors && { errors: response?.errors }),
			}
		}
	}

	async canCheckout(id = false) {
		const apiService = new ApiService()

		if(!id) id = localStorage.getItem('loggedIn')

		const response = await apiService.get(`users/${id}`)

		return !response?.data?.non_attendance_flag;
	}

	async getUserDashboard(id = false) {
		const apiService = new ApiService()

		if(!id) id = localStorage.getItem('loggedIn')

		const response = await apiService.get(`users/${id}/dashboard`)

		if(response.data) {
			return {
				success: true,
				data: response.data,
			}
		}
		else {
			return {
				success: false,
				message: 'Failed to get user dashboard',
			}
		}
	}
}
