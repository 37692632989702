
// Libraries
import * as React from 'react'
import { navigate } from "gatsby"

// Services
import AuthService from "../services/auth"

class PrivateRoute extends React.Component {
	state = {
		isLoading: true
	}

	render() {
		const {isLoading} = this.state

		return (
			isLoading
				? <><p>Loading ...</p></>
				: <>{this.props.children}</>
		)
	}

	async componentDidMount() {
		const authService = new AuthService()

		let authorised = true
		if (!await authService.isLoggedIn()) {
			authorised = false
		}
		if (this.props.admin && !await authService.isAdmin(true)) {
			authorised = false
		}

		if (!authorised) {
			if (this.props.admin) {
				navigate('/admin/login')
			}
			else {
				navigate('/login')
			}
		}
		else {
			this.setState({
				isLoading: false
			})
		}
	}
}

export default PrivateRoute